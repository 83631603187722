import {css} from '@emotion/core';
import {Card, Col, Form, Input, Row} from 'antd';
import {FC, memo} from 'react';
import {Redirect} from 'react-router-dom';
import {useClientOrderNumber, useQuote} from '../../contexts/Quote';
import {useUser} from '../../contexts/Session';

const shipmentCss = css`
  border: none;

  .ant-card-head {
    padding-left: 0;
    .ant-card-head-title {
      padding-bottom: 5px;
    }

    .title {
      line-height: 32px;
      font-weight: bold;
      color: #78787d;
      text-transform: uppercase;
    }
  }

  .ant-card-body {
    border-top: solid 1px #78787d;
    background-color: #ececf1;
    color: #78787d;

    .ant-descriptions-item {
      .ant-descriptions-item-label {
        color: #78787d;
      }

      .ant-descriptions-item-content {
        font-weight: bold;
        color: #78787d;
      }
    }
  }
`;

const ClientOrderData: FC = () => {
  const user = useUser();
  const [clientOrderNumber, setClientOrderNumber] = useClientOrderNumber();

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  return (
    <Card
      css={shipmentCss}
      title={<div className="title">Votre référence</div>}
    >
      <Row type="flex" gutter={20}>
        <Col span={24} xl={12} css={{maxWidth: '100%'}}>
          <label>
            N° de commande Client
            <Input
              style={{marginTop: 10, minWidth: '200px'}}
              maxLength={15}
              value={clientOrderNumber}
              onChange={({target: {value: clientOrderNumber}}) => {
                setClientOrderNumber(clientOrderNumber);
              }}
            />
          </label>
        </Col>
      </Row>
    </Card>
  );
};

export default memo(ClientOrderData);
