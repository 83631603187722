import {css} from '@emotion/core';
import {Card, Col, Form, Row} from 'antd';
import {FC, memo, ReactElement, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useQuote} from '../../contexts/Quote';
import {useSession, useUser} from '../../contexts/Session';
import {Adresse, Transporteur} from '../Orders/data';
import {getDefaultAddress} from '../Quote/patient/ShippingAddressSelect';
import ShipmentForm from './ShipmentForm';
import ShipmentValue from './ShipmentValue';

const {REACT_APP_OFFLINE_MODE} = process.env;

const shipmentCss = css`
  border: none;

  .ant-card-head {
    padding-left: 0;
    .ant-card-head-title {
      padding-bottom: 5px;
    }

    .title {
      line-height: 32px;
      font-weight: bold;
      color: #78787d;
      text-transform: uppercase;
    }
  }

  .ant-card-body {
    border-top: solid 1px #78787d;
    background-color: #ececf1;
    color: #78787d;

    .ant-descriptions-item {
      .ant-descriptions-item-label {
        color: #78787d;
      }

      .ant-descriptions-item-content {
        font-weight: bold;
        color: #78787d;
      }
    }
  }
`;

const Shipment: FC<{
  children?: ReactElement;
}> = ({children}) => {
  const user = useUser();
  const session = useSession();
  const [quote, setQuote] = useQuote();
  const [addresses, setAddresses] = useState<Adresse[]>();

  useEffect(() => {
    if (REACT_APP_OFFLINE_MODE) {
      setAddresses([
        {
          ID: '12345',
          adresse: 'Adr livraison',
          codePostal: '75000',
          raisonSociale: 'OFFLINE',
          type: 'LP',
          ville: 'Paris',
          isoCountry: 'FR',
        } as Adresse,
      ]);
      return;
    }
    if (!session) {
      setAddresses([]);
      return;
    }
    (async () => {
      setAddresses(
        (await session.fetch<Adresse[]>(
          'Clients',
          'getAdressesLivraison',
        )) as Adresse[],
      );
    })();
  }, [session]);

  const [transporters, setTransporters] = useState<Transporteur[]>();

  useEffect(() => {
    if (!session) {
      setTransporters([]);
      return;
    }
    (async () => {
      setTransporters(
        (await session.fetch<Transporteur[]>(
          'Transporteurs',
          'getTRP',
        )) as Transporteur[],
      );
    })();
  }, [session]);

  /* const addresses = useAsync(
    async (session: WakandaService) =>
      REACT_APP_OFFLINE_MODE
        ? [
            {
              ID: '12345',
              adresse: 'Adr livraison',
              codePostal: '75000',
              raisonSociale: 'OFFLINE',
              type: 'LP',
              ville: 'Paris',
              isoCountry: 'FR',
            } as Adresse,
          ]
        : (session &&
            (await session.fetch<Adresse[]>(
              'Clients',
              'getAdressesLivraison',
            ))) ||
          [],
    [sesssion],
  ); */

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const {shippingAddress} = quote;
  const defaultAddress = getDefaultAddress(addresses);

  const address = shippingAddress || defaultAddress;

  const WrappedShipmentForm = Form.create({name: 'shipmentForm'})((props) => (
    <ShipmentForm
      {...props}
      user={user}
      quote={quote}
      onSubmit={(data) => setQuote({...quote, ...data})}
      layout="inline"
      addresses={addresses}
      transporters={transporters}
    />
  ));

  return (
    <Card css={shipmentCss} title={<div className="title">Livraison</div>}>
      <Row type="flex" gutter={20}>
        {address ? (
          <Col
            span={24}
            xl={12}
            css={css`
              margin-top: 10px;
              p {
                margin: 0;
              }
            `}
          >
            <ShipmentValue address={address} />
          </Col>
        ) : null}
        <Col span={24} xl={12} css={{maxWidth: '100%'}}>
          <WrappedShipmentForm />
        </Col>
        {children ? (
          <Col span={24} xl={12}>
            {children}
          </Col>
        ) : null}
      </Row>
    </Card>
  );
};

export default memo(Shipment);
