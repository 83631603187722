import {css} from '@emotion/core';
import {Button, Col, Layout, Row} from 'antd';
import 'core-js/stable';
import {FC, memo, useState} from 'react';
import DocumentTitle from 'react-document-title';
import {hot} from 'react-hot-loader/root';
import {BrowserRouter, Redirect, Route} from 'react-router-dom';
import 'regenerator-runtime/runtime';
import QuoteContext, {
  BaseQuoteData,
  getBaseQuoteOptions,
} from '../contexts/Quote';
import SessionContext from '../contexts/Session';
import Footer from '../Footer';
import Header from '../Header';
import Logout from '../Logout';
import {UserType, WakandaService} from '../util/fetch-wakanda';
import './App.scss';
import Landing from './Landing';
import Login from './Login';
import Orders from './Orders';
import Quote from './Quote';
import Settings from './Settings';
import Summary from './Summary';

const {Content} = Layout;

const baseQuote: BaseQuoteData = getBaseQuoteOptions();
const layoutCss = css`
  &.ant-layout {
    min-height: 100%;
    overflow: auto;
    background-color: white !important;
    > .ant-layout-content {
      padding: 10px 0;
    }
  }
`;

const bannerCss = css`
  margin: 10px auto;
  width: 1200px;
  max-width: 100vw;
  text-align: left;

  @media (max-width: 992px) {
    padding: 0 5px;
    .visible-xl {
      display: none;
    }
    .visible-sm {
      display: initial !important;
    }
  }
  .visible-sm {
    display: none;
  }
`;

const logoCss = css`
  max-height: 50px;
  position: absolute;
  right: 10px;
  z-index: 1;
`;

const {
  REACT_APP_HEAD_TITLE,
  REACT_APP_REDIRECT_FROM,
  REACT_APP_REDIRECT_TO,
  REACT_APP_OFFLINE_MODE,
  REACT_APP_SITE_NAME = 'nimes',
} = process.env;

const baseUrl = REACT_APP_OFFLINE_MODE
  ? 'https://demo-espace-pro-interson-protac.logicgram.fr'
  : 'https://espace-pro.interson-protac.com';

const App: FC = () => {
  const [quote, setQuote] = useState<BaseQuoteData>(baseQuote);
  const [session, setSession] = useState<WakandaService>(new WakandaService());
  const [imageErrorStatus, setImageErrorStatus] = useState(false);
  /* session.resetService = () => setSession(new WakandaService()); */

  if (
    REACT_APP_REDIRECT_FROM &&
    REACT_APP_REDIRECT_TO &&
    window.location.origin === REACT_APP_REDIRECT_FROM
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.location = REACT_APP_REDIRECT_TO;
  }

  if (REACT_APP_SITE_NAME === 'paris') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.location = 'https://store.nimes.interson-protac.com/';
    return <div />;
  }

  const baseDocumentTitle = REACT_APP_HEAD_TITLE || 'Store';

  return (
    <SessionContext.Provider value={[session, setSession]}>
      <QuoteContext.Provider value={[quote, setQuote]}>
        <DocumentTitle title={baseDocumentTitle}>
          <Layout css={layoutCss}>
            <BrowserRouter>
              <Header />
              <Row css={bannerCss}>
                <Col span={24}>
                  <a href={baseUrl} target="_blank" rel="noopener noreferrer">
                    <Button
                      className="visible-xl"
                      size="large"
                      style={{textTransform: 'uppercase', marginLeft: '10px'}}
                      type="primary"
                      icon="left"
                    >
                      Retour à l&apos;accueil
                    </Button>
                    <Button
                      className="visible-sm"
                      style={{textTransform: 'uppercase', marginLeft: '10px'}}
                      type="primary"
                      icon="left"
                    >
                      Retour à l&apos;accueil
                    </Button>
                  </a>
                  {session?.user?.type === UserType.CLIENT &&
                  !imageErrorStatus ? (
                    <img
                      src={`${process.env.REACT_APP_ASSETS_HOST}/centrale/${session?.user.groupID}.png`}
                      alt="Logo centrale"
                      css={logoCss}
                      onError={() => setImageErrorStatus(true)}
                    />
                  ) : null}
                </Col>
              </Row>
              <Content>
                <Route exact path="/">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Accueil'].join(' - ')}
                  >
                    <Redirect to="/login" />
                    {/* <Landing /> */}
                  </DocumentTitle>
                </Route>
                <Route path="/login/:token?">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Connexion'].join(' - ')}
                  >
                    <Login />
                  </DocumentTitle>
                </Route>
                <Route path="/quote">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Commande'].join(' - ')}
                  >
                    {/* <Quote parentPath="/quote" /> */}
                    <Landing />
                  </DocumentTitle>
                </Route>
                <Route exact path="/orders">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Suivi commandes'].join(' - ')}
                  >
                    <Orders />
                  </DocumentTitle>
                </Route>
                <Route exact path="/order/:ID">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Suivi commandes'].join(' - ')}
                  >
                    <Orders />
                  </DocumentTitle>
                </Route>
                <Route path="/orders/edit">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Modification commande'].join(
                      ' - ',
                    )}
                  >
                    <Quote parentPath="/orders/edit" />
                  </DocumentTitle>
                </Route>
                <Route path="/settings">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Paramètres'].join(' - ')}
                  >
                    <Settings />
                  </DocumentTitle>
                </Route>
                <Route path="/cart">
                  <DocumentTitle
                    title={[baseDocumentTitle, 'Panier'].join(' - ')}
                  >
                    <Summary />
                  </DocumentTitle>
                </Route>
              </Content>
              <Footer />
              <Route path="/logout">
                <Logout />
              </Route>
            </BrowserRouter>
          </Layout>
        </DocumentTitle>
      </QuoteContext.Provider>
    </SessionContext.Provider>
  );
};

export default hot(memo(App)) as FC;
